<template>
  <AppLayout>
    <template v-slot:appContent>
      <div>
        <div class="d-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
          <h4 class="">Blogs/Create</h4>
          <div>
            <router-link :to="{...previousRoute}">
              <span class="glow d-flex align-items-center"><i class='bx bx-undo'></i>&nbsp;Back</span>
            </router-link>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <form action="">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-8">
                  <div class="col-12">
                    <div class="form-group">
                      <div>
                        <label for="title">Title *</label>
                        <input v-model="postBlogData.title" type="text" class="form-control" id="title"
                               placeholder="Enter Blog Title">
                      </div>
                      <div v-if="errors.blog.title" class="text-danger">{{ errors.blog.title }}</div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group text-editor">
                      <label>Description *</label>
                      <QuillEditor v-model:content="postBlogData.description" contentType="html" toolbar="full"
                                   theme="snow"/>
                      <div class="text-danger" v-if="errors.blog.description">{{ errors.blog.description }}</div>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-sm-12 col-md-4">
                  <div class="col-12">
                    <div class="form-group">
                      <div class="controls">
                        <label>Blog Category *</label>
                        <VueMultiselect :allowEmpty="false" v-model="selectedBlogCategory" class="multiselect-blue"
                                        :options="blogCategoryOptions"
                                        :close-on-select="true" placeholder="Select Blog Category" label="name"
                                        track-by="name"
                                        :show-labels="false"/>
                      </div>
                      <div class="text-danger" v-if="errors.blogCategoryId">{{ errors.blogCategoryId }}</div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <div class="controls select-tags-wrapper">
                        <label>Tags (optional)</label>
                        <VueMultiselect :taggable="true"
                                        v-model="selectedBlogTags" class="multiselect-blue"
                                        :options="blogTagOptions"
                                        :close-on-select="true" :preselect-first="true" :clear-on-select="true"
                                        :multiple="true" placeholder="Select Blog Tags" label="name"
                                        track-by="name"
                                        :show-labels="false"
                                        @tag="createNewBlogTag"/>
                      </div>
                      <div class="text-danger" v-if="errors.blogTagId">{{ errors.blogTagId }}</div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <div class="controls">
                        <label>Is Featured?</label>
                        <div style="margin-top: .5rem">
                          <div
                              class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                            <div>
                              <input v-model="selectedIsFeatured" type="checkbox" class="custom-control-input" checked
                                     id="blogIsFeatured">
                              <label class="custom-control-label mr-1" for="blogIsFeatured"></label>
                            </div>
                            <span class="font-medium-1">{{ selectedIsFeatured ? "Yes" : "No" }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="text-danger" v-if="errors.blog.isFeatured">{{ errors.blog.isFeatured }}</div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <div class="controls">
                        <label>Status</label>
                        <div style="margin-top: .5rem">
                          <div
                              class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                            <div>
                              <input v-model="selectedStatus" type="checkbox" class="custom-control-input" checked
                                     id="blogStatus">
                              <label class="custom-control-label mr-1" for="blogStatus"></label>
                            </div>
                            <span class="font-medium-1">{{ selectedStatus ? "Active" : "Inactive" }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="text-danger" v-if="errors.blog.status">{{ errors.blog.status }}</div>
                    </div>
                  </div>
                </div>

              </div>
              <div class="row mb-4">
                <div class="col-12">
                  <div class="d-flex justify-content-end">
                    <button @click="createSingleBlog" type="button" class="btn btn-primary update-todo px-4">Create
                      Blog
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </template>
  </AppLayout>
</template>

<script>

// components
import AppLayout from '@/layouts/backEnd/AppLayout.vue'

// core packages
import {mapActions, mapGetters} from 'vuex';

// packages
import VueMultiselect from 'vue-multiselect';
import {QuillEditor} from '@vueup/vue-quill';

// mixins
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";

// css
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import Authorization from "@/components/backEnd/mixins/Authorization";

export default {
  components: {
    AppLayout,
    VueMultiselect,
    QuillEditor,

  },
  name: "BlogCreate",

  mixins: [ShowToastMessage, Loader, Authorization],

  data() {
    return {
      selectedBlogTags: [],
      getBlogCategoriesParams: {
        status: 1,
      },

      postBlogData: {
        blog_category_id: '',
        title: '',
        description: '',
        is_featured: 1,
        status: 1,
      },

      postBlogBlogTagStoreListData: {
        blog_id: null,
        blog_tag_id: null,
      },

      selectedIsFeatured: true,
      selectedStatus: true,

      errors: {
        blog: {
          blogCategoryId: '',
          blogTagId: '',
          title: '',
          description: '',
          isFeatured: '',
          status: ''
        }
      },

      selectedBlogCategory: {
        value: '',
        name: 'None'
      },

    }
  },

  computed: {
    ...mapGetters({
      previousRoute: 'previousRoute',
      authUser: 'appAuthLogin/authUser',
      blog: 'appBlogs/blog'
    }),
    redirectRouteName() {
      return this.can('blog-view-any') ? 'appBlogList' : 'appBlogCreate';
    },

    blogCategoryOptions() {
      let blogCategories = this.$store.getters['appBlogCategories/blogCategories'].map((blogCategory) => {
        let id = blogCategory.id;
        let name = blogCategory.name;
        return {value: id, name: name};
      });
      console.log(blogCategories)
      return [{value: '', name: 'None'}, ...blogCategories];
    },

    blogTagOptions() {
      return this.$store.getters['appBlogTags/blogTags'].map(blogTag => {
        return {
          value: blogTag.id,
          name: blogTag.name,
        };
      });
    },
  },

  watch: {
    selectedBlogCategory(selectedBlogCategory) {
      this.postBlogData.blog_category_id = selectedBlogCategory.value;
    },

    selectedIsFeatured(selectedIsFeatured) {
      this.postBlogData.is_featured = selectedIsFeatured === true ? 1 : 0;
    },

    selectedStatus(selectedStatus) {
      this.postBlogData.status = selectedStatus === true ? 1 : 0;
    }
  },

  methods: {
    ...mapActions({
      getBlogCategories: 'appBlogCategories/getBlogCategories',
      getBlogTags: 'appBlogTags/getBlogTags',
      postBlogBlogTagStoreList: 'appBlogBlogTags/postBlogBlogTagStoreList',

      postBlog: 'appBlogs/postBlog',
      postBlogTag: 'appBlogTags/postBlogTag',

    }),

    async getBlogCategoryList() {
      await this.getBlogCategories(this.getBlogCategoriesParams);
    },

    async getBlogTagList() {
      await this.getBlogTags();
    },



    async createSingleBlog() {
      this.loader(true);
      this.postBlogData.created_by = this.authUser?.id,
      await this.postBlog(this.postBlogData).then(async (response) => {
        this.loader(false);
        if (response.status === 201) {
          const toastObj = {
            message: 'Blog created successful.',
            type: 'success'
          };

          this.showToastMessage(toastObj);

          if(this.selectedBlogTags.length) {
            this.postBlogBlogTagStoreListData.blog_id = this.blog.id;
            this.postBlogBlogTagStoreListData.blog_tag_id = this.selectedBlogTags.map(blogTag => {
              return blogTag.value;
            });
            await this.createBlogBlogTagsStoreList();
          }

          await this.$router.push({
            name: this.redirectRouteName,
          });

        }

        this.errors.blogCategoryId = response.errors && response.errors.blog_category_id ? response.errors.blog_category_id[0] : '';
        this.errors.blogTagId = response.errors && response.errors.blog_tag_id ? response.errors.blog_tag_id[0] : '';
        this.errors.blog.title = response.errors && response.errors.title ? response.errors.title[0] : '';
        this.errors.blog.description = response.errors && response.errors.description ? response.errors.description[0] : '';
        this.errors.blog.isFeatured = response.errors && response.errors.isFeatured ? response.errors.isFeatured[0] : '';
        this.errors.blog.status = response.errors && response.errors.status ? response.errors.status[0] : '';

        if (response.message) {
          this.showToastMessage(response);
        }

      });
    },

    async createBlogBlogTagsStoreList() {
      this.postBlogBlogTagStoreList(this.postBlogBlogTagStoreListData).then(async response => {
        if (response.message) {
          this.showToastMessage(response);
        }
      })
    },

    async createNewBlogTag(createNewBlogTag) {

      let data = {
        name: createNewBlogTag,
      }

      await this.postBlogTag(data);

      let blogTag = this.$store.getters['appBlogTags/blogTag'];
      let blogTagObj = { value: blogTag.id, name: blogTag.name };

      await this.getBlogTagList();

      this.selectedBlogTags.push(blogTagObj);
    }

  },

  async mounted() {
    this.loader(true);
    await this.getBlogCategoryList();
    await this.getBlogTagList();
    this.loader(false);
  }

}
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style scoped>
.multiselect__tags:focus-within {

  /* border:1px solid red !important; */
  border-color: #5A8DEE;

}

.multiselect__tags {
  min-height: 0;
  max-height: 40px !important;
  border-color: #DFE3E7;
}

.ql-editor {
  height: 70vh !important;
}
</style>